import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - L'axe de rotation" />
    <h3 className='underline-title'>L'axe de rotation</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ni Von Braun ni Einstein n'a effleuré son omniscience <br />
        Il n'a aucun diplôme aucun brevet<br />
        <span className="par-starting"></span>Surtout aucune distinction honorifique <br />
      </p>
      <p>
        Ses décrets ne sont ni datés ni numérotés<br />
        <span className="par-starting"></span>Une seule fois promulgués<br />
        Ses greffiers et arbitres pensent le faire pour lui <br />
        <span className="par-starting"></span>Nenni <br />
      </p>
      <p>
        Souverain dans la plénitude <br />
        <span className="par-starting"></span>l'omnipotence même<br />
      </p>
      <p>
        Ses diktats s'exécutent sans faille <br />
        <span className="par-starting"></span>sans cadère<br />
        <span className="par-starting"></span>sans jièmpère<br />
      </p>
      <p>
        Maître des maîtres <br />
        Tout de lui tout par lui tout à lui tout en lui <br />
      </p>
      <p>
        L'axe astronomique <br />
        <span className="par-starting"></span>autour duquel s'effectue le mouvement de rotation <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
